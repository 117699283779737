import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import SearchBarSimple from './SearchBarSimple';

const TextList = ({ className }) => {
  const [textList, setTextList] = useState([]);

  useEffect(() => {
    const getAllText = () => {
      const allText = [];
      const walker = document.createTreeWalker(
        document.body,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      let node;
      while ((node = walker.nextNode())) {
        allText.push({
          text: node.textContent.trim(),
          node,
        });
      }

      return allText;
    };

    const allText = getAllText();
    setTextList(allText);
  }, []);

  const handleResultClick = (node) => {
    // Scroll to the element and highlight it
    node.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    node.parentElement.style.backgroundColor = '#e2db82';

    // Remove the highlighting after a short delay (e.g., 2 seconds)
    setTimeout(() => {
      node.parentElement.style.backgroundColor = '';
    }, 2000);
  };

  return (
    <div className={className}>
      <SearchBarSimple data={textList} handleResultClick={handleResultClick} isStringArray={false} />
    </div>
  );
};

export default TextList;
